import { Component } from '@angular/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'ava-divider',
  template: ` <nz-divider class="m-0" /> `,
  standalone: true,
  imports: [NzDividerModule],
})
export class AvaDividerComponent {}
